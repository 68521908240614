import { FC, useEffect, useMemo, useState } from 'react';
import styles from './header.module.scss';
import { ReactComponent as TUMLogo } from '../../../assets/svg/tum-logo.svg';
import { UserInfo } from '../user-info/user-info';
import { Button } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { Login } from '../../building-blocks/login/login';
import RegisterDialog from '../../views/register-dialog/register-dialog';
import { useUrlSearchParams } from 'use-url-search-params';
import { Param, START_VIEW_PATH } from '../../../utils/constants';
import { useResponsiveBreakpoints } from '../../../utils/hooks/responsive.hooks';
import { HomeSVGIcon, MenuSVGIcon } from '@react-md/material-icons';
import { Link, useNavigate } from 'react-router-dom';

export interface HeaderUserProps {
  /** The display name of the User */
  displayName: string;
  /** The role of the User */
  role: string;
}

export interface HeaderProps {
  /** The displayed header title besides the logo */
  title: string;
  /** The user, if any */
  user?: HeaderUserProps;
  /** Callback for click on menu button*/
  onOpenMenuClick?: () => void;
  /** decide if login should be displayed*/
  isCsrfMWRunning?: boolean;
}

/** Utility Components */

type HasTitleProps = { title: string };
type HasAuthCallbackProps = { onLoginClick: () => void; onRegisterClick: () => void };
type HasUserProps = { user?: HeaderUserProps };
type HasCsrfMWRunning = {
  isCsrfMWRunning?: boolean;
};
type HasOnOpenMenuCallbackProps = { onOpenMenuClick?: () => void };

const HeaderHeading: FC<HasTitleProps> = ({ title }) => <span className={styles.Heading}>{title}</span>;
const HeaderBrand: FC<HasTitleProps & { ariaLabel: string }> = ({ title, ariaLabel }) => (
  <Link to={START_VIEW_PATH} className={styles.HeaderBrand} aria-label={ariaLabel}>
    <TUMLogo title="TUM" />
    <HeaderHeading title={title} />
  </Link>
);
const UserAuthButtonGroup: FC<HasAuthCallbackProps> = ({ onRegisterClick, onLoginClick }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.ButtonGroup}>
      <Button kind="secondary" size="field" onClick={onRegisterClick}>
        {t('actions.register')}
      </Button>
      <Button kind="primary" size="field" onClick={onLoginClick}>
        {t('actions.login')}
      </Button>
    </div>
  );
};
const HeaderContent: FC<HasAuthCallbackProps & HasTitleProps & HasUserProps & HasCsrfMWRunning> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="global-content-wrapper">
      <HeaderBrand title={props.title} ariaLabel={t('aria.goToHomePage')} />
      {props.user ? (
        <UserInfo role={props.user.role} name={props.user.displayName} />
      ) : props.isCsrfMWRunning ? (
        <UserAuthButtonGroup onLoginClick={props.onLoginClick} onRegisterClick={props.onRegisterClick} />
      ) : (
        <></>
      )}
    </div>
  );
};

const HeaderContentMobile: FC<
  HasAuthCallbackProps & HasTitleProps & HasUserProps & HasOnOpenMenuCallbackProps & HasCsrfMWRunning
> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.UserBar}>
        {props.user ? (
          <UserInfo role={props.user.role} name={props.user.displayName} />
        ) : props.isCsrfMWRunning ? (
          <UserAuthButtonGroup onLoginClick={props.onLoginClick} onRegisterClick={props.onRegisterClick} />
        ) : (
          <></>
        )}
      </div>
      <div className={styles.HeaderContentMobile}>
        {/* Show an open menu button if there is a menu to be shown and a home button otherwise */}
        {props.onOpenMenuClick ? (
          <Button kind="ghost" onClick={props.onOpenMenuClick} aria-controls="navMenu" className={styles.MenuButton}>
            <MenuSVGIcon />
            {t('actions.menu')}
          </Button>
        ) : (
          <Button kind="ghost" onClick={() => navigate(START_VIEW_PATH)} className={styles.HomeButton}>
            <HomeSVGIcon />
          </Button>
        )}
        <HeaderBrand title={props.title} ariaLabel={t('aria.goToHomePage')} />
      </div>
      {/* Render a border if there is just a home button and no menu */}
      <div className="bx--tabs--border" />
    </>
  );
};

export const Header: FC<HeaderProps> = ({ title, user, onOpenMenuClick, isCsrfMWRunning }) => {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [loginTabIndex, setLoginTabIndex] = useState(1);
  const [params, setParams] = useUrlSearchParams({}, {});

  useEffect(() => {
    if (params[Param.REGISTER] && !openRegisterModal) {
      setOpenRegisterModal(true);
    }
  }, [params[Param.REGISTER]]);

  useEffect(() => {
    if (!params[Param.TAB] && loginTabIndex !== 1) {
      // Reset tab index after dialog is closed
      setTimeout(() => {
        setLoginTabIndex(1);
      }, 500);
    }
  }, [params[Param.TAB]]);

  useEffect(() => {
    if (params[Param.LOGIN] && !openLoginModal) {
      if (params[Param.TAB] && Number(params[Param.TAB]) !== loginTabIndex) {
        setLoginTabIndex(Number(params[Param.TAB]));
      }
      // Set tab index first and open the dialog after this
      setTimeout(() => {
        setOpenLoginModal(true);
      }, 100);
    }
  }, [params[Param.LOGIN]]);

  const headerContentProps = useMemo(() => {
    return {
      onLoginClick: () => {
        setOpenLoginModal(true);
        setParams({ [Param.LOGIN]: 1 });
      },
      onRegisterClick: () => {
        setOpenRegisterModal(true);
        setParams({ [Param.REGISTER]: 1 });
      },
      user,
      title,
      isCsrfMWRunning
    };
  }, [user, title, setParams, setOpenLoginModal, setOpenRegisterModal, isCsrfMWRunning]);

  const breakpoints = useResponsiveBreakpoints();

  return (
    <header className={styles.AppHeader}>
      {breakpoints.isDesktop ? (
        <HeaderContent {...headerContentProps} />
      ) : (
        <HeaderContentMobile {...headerContentProps} onOpenMenuClick={onOpenMenuClick} />
      )}

      {!user && (
        <>
          <Login
            selectedTabIndex={loginTabIndex}
            open={openLoginModal}
            closeModal={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setParams({ [Param.LOGIN]: undefined, [Param.TAB]: undefined });
              setOpenLoginModal(false);
            }}
          />
          <RegisterDialog
            open={openRegisterModal}
            closeModal={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setParams({ [Param.REGISTER]: undefined });
              setOpenRegisterModal(false);
            }}
          />
        </>
      )}
    </header>
  );
};

export default Header;
